import { Box, Typography } from "@mui/material";
import React from "react";

export default function ErrorMessage({ message }) {
  return (
    <Box
      sx={{
        backgroundColor: "#FFD7D7",
        width: { xs: "100%"},
        borderRadius: "12px",

        height: "48px",
        display: "flex",
        mb: 2,
        alignItems: "center",
      }}
    >
      <svg
        width={20}
        color="red"
        fill="none"
        strokeWidth={1.5}
        stroke="currentColor"
        viewBox="0 0 24 24"
        xmlns="http://www.w3.org/2000/svg"
        aria-hidden="true"
        style={{ paddingLeft: "15px" }}
      >
        <path
          strokeLinecap="round"
          strokeLinejoin="round"
          d="M12 9v3.75m9-.75a9 9 0 1 1-18 0 9 9 0 0 1 18 0Zm-9 3.75h.008v.008H12v-.008Z"
        />
      </svg>
      <Typography
        sx={{
          fontFamily: "Poppins, sans-serif",
          fontSize: "12px",
          textTransform: "none",
          color: "red",
          ml: 1,
        }}
      >
        {message}
      </Typography>
    </Box>
  );
}
