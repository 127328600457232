import React from "react";
import { Box, Button, Typography } from "@mui/material";
import Google from "../../images/Google.svg";
import Facebook from "../../images/Facebook.svg";

export default function OtherLogins() {
  return (
    <Box
      sx={{
        width: { xs: "100%", md: "388px" },
      }}
    >
      <Box
        sx={{
          mt: 2,
          mb: 2,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Box
          sx={{
            borderTop: "1px solid #D1D1D1",
            width: "100px",
          }}
        ></Box>
        <Typography
          sx={{
            margin: "0 30px 0 30px",
            fontFamily: "Poppins, sans-serif",
            fontSize: "20px",
            textTransform: "none",
            textAlign: "center",
          }}
        >
          Or
        </Typography>
        <Box
          sx={{
            borderTop: "1px solid #D1D1D1",
            width: "100px",
          }}
        ></Box>
      </Box>
      <Button
        component="label"
        variant="contained"
        sx={{
          backgroundColor: "#F3F9FA",
          width: { xs: "100%", md: "388px" },
          height: "52px",
          borderRadius: "12px",
          color: "#313957",
          ":hover": {
            color: "white",
          },
          mb: 3,
        }}
        startIcon={<img src={Google} alt="google" />}
      >
        <Typography
          sx={{
            fontFamily: "Poppins, sans-serif",
            fontSize: "16px",
            textTransform: "none",
          }}
        >
          Sign in with Google
        </Typography>
      </Button>
      <br />
      <Button
        component="label"
        variant="contained"
        sx={{
          backgroundColor: "#F3F9FA",
          width: { xs: "100%", md: "388px" },
          height: "52px",
          borderRadius: "12px",
          color: "#313957",
          ":hover": {
            color: "white",
          },
        }}
        startIcon={<img src={Facebook} alt="facebook" />}
      >
        <Typography
          sx={{
            fontFamily: "Poppins, sans-serif",
            fontSize: "16px",
            textTransform: "none",
          }}
        >
          Sign in with Facebook
        </Typography>
      </Button>
      <br />
    </Box>
  );
}
