import { Box, Typography } from "@mui/material";
import React from "react";

export default function Description() {
  return (
    <Box
      sx={{
        width: { xs: "100%", md: "400px", lg: "490px" },
      }}
    >
      {" "}
      <Typography
        sx={{
          fontFamily: "Poppins, sans-serif",
          fontSize: { xs: "16px", md: "20px" },
          mb: 3,
        
        }}
      >
        Today is a new day. It's your day. You shape it. Sign in to start
        managing your projects.
      </Typography>
    </Box>
  );
}
