import React from "react";
import { Box, Container, Grid, Link, Typography } from "@mui/material";
import DotyYellow from "../images/dotyellowcircle.svg";
import Logo from "../images/tse_logo.svg";
import CreateNewPassword from "./resetPassword/CreateNewPassword";
import { useParams } from "react-router-dom";

export default function ResetPassword() {
  const { code } = useParams();
  console.log(code);
  return (
    <div>
      <Container>
        <Box
          sx={{
            flexGrow: 1,
            display: "flex",
            minHeight: "90vh",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Grid>
            <Grid
              item
              md={6}
              xs={12}
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                mt: { md: 5 },
              }}
            >
              <img src={Logo} alt="logo" />
              <br />

              <Box
                sx={{
                  width: { xs: "100%", md: "400px", lg: "490px" },
                }}
              >
                <Typography
                  sx={{
                    fontFamily: "Poppins, sans-serif",
                    fontSize: { xs: "16px", md: "20px" },
                    mb: 3,
                    textAlign: "center",
                  }}
                >
                  <span style={{ fontWeight: 700 }}>
                    {" "}
                    Enter your new password and confirm password{" "}
                  </span>
                  we'll update your password.
                </Typography>
              </Box>
            </Grid>

            <Grid
              item
              md={5}
              xs={12}
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: { xs: "center" },
              }}
            >
              <CreateNewPassword code={code} />
              <Box
                sx={{
                  width: { xs: "100%", md: "388px" },
                  mt: 2,
                  mb: 2,
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Typography
                  sx={{
                    fontFamily: "Poppins, sans-serif",
                    fontSize: "16px",
                    textTransform: "none",
                    color: "#313957",
                    textAlign: "center",
                  }}
                >
                  Don't you have an account?{" "}
                  <Link
                    sx={{
                      fontFamily: "Poppins, sans-serif",
                      fontSize: "16px",
                      fontWeight: "500",
                      color: "#1E4AE9",
                    }}
                    overlay="true"
                    href="/sign-up"
                    underline="none"
                  >
                    {" "}
                    Sign up
                  </Link>
                </Typography>
              </Box>
            </Grid>
          </Grid>
        </Box>
        <Box
          sx={{
            mt: 2,
            mb: 2,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Typography
            sx={{
              fontFamily: "Poppins, sans-serif",
              fontSize: { xs: "12px ", md: "16px" },
              textTransform: "none",
              color: "#959CB6",
            }}
          >
            © 2023 ALL RIGHTS RESERVED
          </Typography>
        </Box>
      </Container>
      <Box
        sx={{
          display: { xs: "none", md: "block" },
          position: "absolute",
          bottom: 0,
        }}
      >
        <img src={DotyYellow} alt="DotyYellow" />
      </Box>
    </div>
  );
}
