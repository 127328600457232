import { useState, useEffect } from "react";
import {
  Alert,
  AlertTitle,
  Button,
  CircularProgress,
  TextField,
  Typography,
  Box,
} from "@mui/material";
import axios from "axios";

export default function EmailSendSection() {
  const [email, setEmail] = useState("");
  const [emailError, setEmailError] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [disableButton, setDisableButton] = useState(false);
  const [countdown, setCountdown] = useState(15);
  const [alert, setAlert] = useState(null);

  const handleSendEmail = async () => {
    // Validate email
    if (!email) {
      setEmailError("Email is required");
      return;
    } else {
      setEmailError("");
    }

    // Call your backend or authentication service to send the reset email
    if (email) {
      try {
        setIsLoading(true);
        setDisableButton(true);

        const response = await axios.post(
          "https://96a8-2402-d000-a400-949f-f47b-384a-e2fd-bb8e.ngrok-free.app/api/auth/forgot-password",
          {
            email,
          }
        );

        // Handle success
        setAlert({
          type: "success",
          message: "Reset email sent successfully",
        });
      } catch (error) {
        // Handle error
        setAlert({
          type: "error",
          message: `Error sending reset email: ${error.response.data.message}`,
        });
      } finally {
        // Clear loading state after 30 seconds
        setTimeout(() => {
          setIsLoading(false);
          setDisableButton(false);
          setCountdown(15); // Reset countdown
        }, 15000);
      }
    } else {
      console.log("Email is required");
    }
  };

  useEffect(() => {
    let countdownTimer;

    if (disableButton) {
      countdownTimer = setInterval(() => {
        setCountdown((prevCountdown) => prevCountdown - 1);
      }, 1000);
    }

    return () => {
      // Clear timeout in case component unmounts before the timeout finishes
      clearInterval(countdownTimer);
    };
  }, [disableButton]);

  return (
    <Box width="100%">
      <Typography
        sx={{
          fontFamily: "Poppins, sans-serif",
          fontSize: "16px",
          fontWeight: "600",
        }}
      >
        {"Email"}
      </Typography>
      <TextField
        margin="normal"
        required
        fullWidth
        id="email"
        autoComplete="email"
        autoFocus
        variant="outlined"
        placeholder={"Example@email.com"}
        onChange={(e) => setEmail(e.target.value)}
        error={!!emailError}
        helperText={emailError}
        InputProps={{
          sx: {
            width: { xs: "100%" },
            borderRadius: "12px",
            backgroundColor: "#F7FBFF",
            height: "48px",
          },
        }}
      />
      <br /> <br />
      <Button
        variant="contained"
        disableElevation
        disabled={isLoading || disableButton}
        onClick={handleSendEmail}
        sx={{
          backgroundColor: "#162D3A",
          width: { xs: "100%" },
          height: "52px",
          borderRadius: "12px",
          ":hover": {
            backgroundColor: "#000000",
          },
        }}
      >
        {isLoading ? (
          // <CircularProgress size={24} color="inherit" />
          <Typography
          sx={{
            fontFamily: "Poppins, sans-serif",
            fontSize: "20px",
            textTransform: "none",
          }}
        >
    {disableButton ? `Re-try in ${countdown}s` : "Re-send Link"}
        </Typography>
        ) : (
          <Typography
            sx={{
              fontFamily: "Poppins, sans-serif",
              fontSize: "20px",
              textTransform: "none",
            }}
          >
      {disableButton ? `Re-send Link ${countdown}s` : "Send Link"}
          </Typography>
        )}
      </Button>
      {/* Display alert if applicable */}
      {alert && (
        <Alert severity={alert.type} sx={{ mt: 2 }}>
          <AlertTitle>
            {alert.type === "success" ? "Success" : "Error"}
          </AlertTitle>
          {alert.message}
        </Alert>
      )}
    </Box>
  );
}
