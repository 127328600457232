import { Route, Routes } from "react-router-dom";
import Login from "./layouts/Login";
import MemberRegister from "./layouts/MemberRegister";
import ForgotPassword from "./layouts/ForgotPassword";
import Home from "./layouts/Home";
import { Protector } from "./helper";
import ResetPassword from "./layouts/ResetPassword";

function App() {
  return (
    <Routes>
      <Route path="/" element={<Protector Component={Home} />} />
      <Route path="login" element={<Login />} />
      <Route path="sign-up" element={<MemberRegister />} />
      <Route path="forgot-password" element={<ForgotPassword />} />
      <Route path="reset-password/:code" element={<ResetPassword />} />
    </Routes>
  );
}
export default App;
