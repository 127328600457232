import React, { useState } from "react";
import { Box, Button, TextField, Typography } from "@mui/material";
import axios from "axios";
import { useNavigate } from "react-router-dom";

const initialUser = {
  username: "",
  firstName: "",
  lastName: "",
  email: "",
  contactNumber: "",
  city: "",
  password: "",
};
export default function RegisterForm() {
  const [emailError, setEmailError] = useState("");
  const navigate = useNavigate();
  const [user, setUser] = useState(initialUser);

  const validateEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const signup = async () => {
    if (!user.email) {
      setEmailError("Email is required");
    } else if (!validateEmail(user.email)) {
      setEmailError("Invalid email format");
    } else {
      setEmailError("");
    }
    try {
      const url = `http://localhost:1337/api/auth/local/register`;
      if (
        user.username &&
        user.firstName &&
        user.lastName &&
        user.email &&
        user.contactNumber &&
        user.city &&
        user.password
      ) {
        const res = await axios.post(url, user);

        if (!!res) {
          // toast.success("Registered successfully!", {
          //   hideProgressBar: true,
          // });
          console.log("REGISTED SUCCESSFULLY", res);
          setUser(initialUser);
          navigate("/login");
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleUserChange = ({ target }) => {
    const { name, value } = target;
    setUser((currentUser) => ({
      ...currentUser,
      [name]: value,
    }));
  };

  return (
    <>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          width: { xs: "100%", md: "388px" },
          mt: 3,
        }}
      >
        {/* username */}
        <Typography
          sx={{
            fontFamily: "Poppins, sans-serif",
            fontSize: "16px",
            fontWeight: "600",
            mb: 1,
          }}
        >
          {"User Name"}
        </Typography>
        <TextField
          required
          fullWidth
          id="username"
          variant="outlined"
          placeholder={"User name"}
          name="username"
          value={user.username}
          onChange={handleUserChange}
          InputProps={{
            sx: {
              borderRadius: "12px",
              backgroundColor: "#F7FBFF",
              height: "48px",
            },
          }}
        />
        <br />
        {/* First Name */}
        <Typography
          sx={{
            fontFamily: "Poppins, sans-serif",
            fontSize: "16px",
            fontWeight: "600",
            mb: 1,
          }}
        >
          {"First Name"}
        </Typography>
        <TextField
          required
          fullWidth
          id="firstName"
          variant="outlined"
          placeholder={"Enter Your First Name"}
          name="firstName"
          value={user.firstName}
          onChange={handleUserChange}
          InputProps={{
            sx: {
              borderRadius: "12px",
              backgroundColor: "#F7FBFF",
              height: "48px",
            },
          }}
        />
        <br />
        {/* Last Name */}
        <Typography
          sx={{
            fontFamily: "Poppins, sans-serif",
            fontSize: "16px",
            fontWeight: "600",
            mb: 1,
          }}
        >
          {"Last Name"}
        </Typography>
        <TextField
          required
          fullWidth
          id="lastName"
          variant="outlined"
          placeholder={"Enter Your Last Name"}
          name="lastName"
          value={user.las}
          onChange={handleUserChange}
          InputProps={{
            sx: {
              borderRadius: "12px",
              backgroundColor: "#F7FBFF",
              height: "48px",
            },
          }}
        />
        <br />
        {/* Email */}
        <Typography
          sx={{
            fontFamily: "Poppins, sans-serif",
            fontSize: "16px",
            fontWeight: "600",
            mb: 1,
          }}
        >
          {"Email"}
        </Typography>
        <TextField
          required
          fullWidth
          autoComplete="email"
          variant="outlined"
          placeholder={"Example@email.com"}
          name="email"
          value={user.email}
          onChange={handleUserChange}
          error={!!emailError}
          helperText={emailError}
          InputProps={{
            sx: {
              borderRadius: "12px",
              backgroundColor: "#F7FBFF",
              height: "48px",
            },
          }}
        />
        <br />
        {/* Contact Number */}
        <Typography
          sx={{
            fontFamily: "Poppins, sans-serif",
            fontSize: "16px",
            fontWeight: "600",
            mb: 1,
          }}
        >
          {"Contact Number"}
        </Typography>
        <TextField
          required
          fullWidth
          id="contactNumber"
          variant="outlined"
          placeholder={"Contact Number"}
          name="contactNumber"
          value={user.contactNumber}
          onChange={handleUserChange}
          InputProps={{
            sx: {
              borderRadius: "12px",
              backgroundColor: "#F7FBFF",
              height: "48px",
            },
          }}
        />
        <br />
        {/* City */}
        <Typography
          sx={{
            fontFamily: "Poppins, sans-serif",
            fontSize: "16px",
            fontWeight: "600",
            mb: 1,
          }}
        >
          {"City"}
        </Typography>
        <TextField
          required
          fullWidth
          id="city"
          autoComplete="city"
          variant="outlined"
          placeholder={"Enter Your City"}
          name="city"
          value={user.city}
          onChange={handleUserChange}
          InputProps={{
            sx: {
              borderRadius: "12px",
              backgroundColor: "#F7FBFF",
              height: "48px",
            },
          }}
        />
        <br />
        {/* Password */}
        <Typography
          sx={{
            fontFamily: "Poppins, sans-serif",
            fontSize: "16px",
            fontWeight: "600",
            mb: 1,
          }}
        >
          {"Password"}
        </Typography>
        <TextField
          required
          fullWidth
          type="password"
          id="password"
          variant="outlined"
          placeholder={"at least 8 characters"}
          autoComplete="current-password"
          name="password"
          value={user.password}
          onChange={handleUserChange}
          InputProps={{
            sx: {
              borderRadius: "12px",
              backgroundColor: "#F7FBFF",
              height: "48px",
            },
          }}
        />
        <br />
        <Button
          variant="contained"
          disableElevation
          onClick={signup}
          sx={{
            backgroundColor: "#162D3A",
            width: { xs: "100%", md: "388px" },
            height: "52px",
            borderRadius: "12px",
            ":hover": {
              backgroundColor: "#000000",
            },
          }}
        >
          <Typography
            sx={{
              fontFamily: "Poppins, sans-serif",
              fontSize: "20px",
              textTransform: "none",
            }}
          >
            Sign up
          </Typography>
        </Button>
      </Box>
    </>
  );
}
