import React, { useState } from "react";
import { Box, Button, TextField, Typography } from "@mui/material";
import ErrorMessage from "../../components/ErrorMessage";
import axios from "axios";
import { Link, useNavigate } from "react-router-dom";
import { storeUser } from "../../helper";

const initialUser = { password: "", identifier: "" };
export default function LoginAction() {
  const [emailError, setEmailError] = useState("");
  const [passwordError, setPasswordError] = useState("");
  const [showErrorMessage, setShowErrorMessage] = useState(false);

  const validateEmail = (identifier) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(identifier);
  };

  const [user, setUser] = useState(initialUser);
  const navigate = useNavigate();

  const handleChange = ({ target }) => {
    const { name, value } = target;
    setUser((currentUser) => ({
      ...currentUser,
      [name]: value,
    }));
  };

  const handleLogin = async () => {
    if (!user.identifier) {
      setEmailError("Email is required");
    } else if (!validateEmail(user.identifier)) {
      setEmailError("Invalid email format");
    } else {
      setEmailError("");
    }

    // Validate password
    if (!user.password) {
      setPasswordError("Password is required");
    } else {
      setPasswordError("");
    }
    ///

    const url = `http://localhost:1337/api/auth/local`;
    try {
      if (user.identifier && user.password) {
        const { data } = await axios.post(url, user);
        if (data.jwt) {
          storeUser(data);
          console.log(data)
          setShowErrorMessage(false);
          setUser(initialUser);
          navigate("/");
        }
      }
    } catch (error) {
      setShowErrorMessage(true);
    }
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        width: { xs: "100%", md: "388px" },
      }}
    >
      {showErrorMessage && <ErrorMessage message={"Incorrect username or password."} />}
      <Typography
        sx={{
          fontFamily: "Poppins, sans-serif",
          fontSize: "16px",
          fontWeight: "600",
        }}
      >
        {"Email"}
      </Typography>
      <TextField
        margin="normal"
        required
        fullWidth
        name="identifier"
        value={user.identifier}
        autoFocus
        variant="outlined"
        placeholder={"Example@email.com"}
        onChange={handleChange}
        error={!!emailError}
        helperText={emailError}
        InputProps={{
          sx: {
            borderRadius: "12px",
            backgroundColor: "#F7FBFF",
            height: "48px",
          },
        }}
      />
      <br />
      <Typography
        sx={{
          fontFamily: "Poppins, sans-serif",
          fontSize: "16px",
          fontWeight: "600",
        }}
      >
        {"Password"}
      </Typography>
      <TextField
        margin="normal"
        required
        fullWidth
        name="password"
        type="password"
        variant="outlined"
        value={user.password}
        placeholder={"at least 8 characters"}
        autoComplete="current-password"
        onChange={handleChange}
        error={!!passwordError}
        helperText={passwordError}
        InputProps={{
          sx: {
            borderRadius: "12px",
            backgroundColor: "#F7FBFF",
            height: "48px",
          },
        }}
      />
      <br />
      <Link
        to="/forgot-password"
        underline="none"
        sx={{
          display: "flex",
          justifyContent: "flex-end",
        }}
      >
        <Typography
          sx={{
            fontFamily: "Poppins, sans-serif",
            fontSize: "16px",
            fontWeight: "500",
            color: "#1E4AE9",
            textDecoration: "none",
            mb: 2,
          }}
        >
          Forgot Password?
        </Typography>
      </Link>
      <Button
        variant="contained"
        disableElevation
        onClick={handleLogin}
        sx={{
          backgroundColor: "#162D3A",
          width: { xs: "100%", md: "388px" },
          height: "52px",
          borderRadius: "12px",
          ":hover": {
            backgroundColor: "#000000",
          },
        }}
      >
        <Typography
          sx={{
            fontFamily: "Poppins, sans-serif",
            fontSize: "20px",
            textTransform: "none",
          }}
        >
          Sign in
        </Typography>
      </Button>
    </Box>
  );
}
