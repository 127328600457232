import React, { useState } from "react";
import { Box, Button, TextField, Typography } from "@mui/material";
import axios from "axios";
import ErrorMessage from "../../components/ErrorMessage";
import { useNavigate } from "react-router-dom";

const createUserNewPassword = { password: "", passwordConfirmation: "" };

export default function CreateNewPassword({ code }) {
  const [newPassword, setNewPassword] = useState(createUserNewPassword);

  const [emailError, setEmailError] = useState("");
  const [showErrorMessage, setShowErrorMessage] = useState(false);

  const navigate = useNavigate();

  const handlecreateUserNewPassword = async () => {
    if (
      newPassword.password !== "" &&
      newPassword.passwordConfirmation !== ""
    ) {
      if (newPassword.password === newPassword.passwordConfirmation) {
        axios
          .post("http://localhost:1337/api/auth/reset-password", {
            code: code,
            password: newPassword.password,
            passwordConfirmation: newPassword.passwordConfirmation,
          })
          .then((response) => {
            // Handle success.
            alert("Your user's password has been changed.");
            navigate("/login");
          })
          .catch((error) => {
            // Handle error.
            alert("An error occurred:", error.response);
          });
      } else {
        setShowErrorMessage("Password does not match");
      }
    } else {
      setShowErrorMessage("Please fill all the fields");
    }
  };

  const handleChange = ({ target }) => {
    const { name, value } = target;
    setNewPassword((currentPassword) => ({
      ...currentPassword,
      [name]: value,
    }));
  };

  return (
    <Box width={"100%"}>
      {showErrorMessage && <ErrorMessage message={showErrorMessage} />}
      <Typography
        sx={{
          fontFamily: "Poppins, sans-serif",
          fontSize: "16px",
          fontWeight: "600",
          mb: 1,
        }}
      >
        {"New Password"}
      </Typography>
      <TextField
        required
        fullWidth
        type="password"
        variant="outlined"
        placeholder={"at least 8 characters"}
        name="password"
        value={newPassword.password}
        onChange={handleChange}
        InputProps={{
          sx: {
            borderRadius: "12px",
            backgroundColor: "#F7FBFF",
            height: "48px",
            width: { xs: "100%" },
          },
        }}
      />
      <br />
      <Typography
        sx={{
          fontFamily: "Poppins, sans-serif",
          fontSize: "16px",
          fontWeight: "600",
          mb: 1,
        }}
      >
        {"Confirm Password"}
      </Typography>
      <TextField
        required
        fullWidth
        type="password"
        variant="outlined"
        placeholder={"Confirm Password"}
        name="passwordConfirmation"
        value={newPassword.passwordConfirmation}
        onChange={handleChange}
        InputProps={{
          sx: {
            borderRadius: "12px",
            backgroundColor: "#F7FBFF",
            height: "48px",
            width: { xs: "100%" },
          },
        }}
      />
      <br />
      <br />
      <Box>
        <Button
          variant="contained"
          disableElevation
          onClick={handlecreateUserNewPassword}
          sx={{
            backgroundColor: "#162D3A",
            width: { xs: "100%" },
            height: "52px",
            borderRadius: "12px",
            ":hover": {
              backgroundColor: "#000000",
            },
          }}
        >
          <Typography
            sx={{
              fontFamily: "Poppins, sans-serif",
              fontSize: "20px",
              textTransform: "none",
            }}
          >
            Change Password
          </Typography>
        </Button>
        <br />
        <Button
          variant="outlined"
          disableElevation
          onClick={() => navigate("/login")}
          sx={{
            backgroundColor: "white",
            color: "#162D3A",
            width: { xs: "100%" },
            height: "52px",
            mt: 2,
            border: "1px solid #162D3A",
            borderRadius: "12px",
            ":hover": {
              backgroundColor: "#000000",
              color: "white",
              border: "1px solid #162D3A",
            },
          }}
        >
          <Typography
            sx={{
              fontFamily: "Poppins, sans-serif",
              fontSize: "20px",
              textTransform: "none",
            }}
          >
            Back
          </Typography>
        </Button>
      </Box>
    </Box>
  );
}
